<script lang="ts" setup>
const { t } = useT();
</script>
<template>
	<div class="favorite-empty" data-tid="favorite-empty">
		<NuxtImg
			alt="favorite-empty"
			quality="100"
			format="avif"
			height="324"
			width="560"
			src="/nuxt-img/card-games/favorite-empty@2x.webp"
			class="image"
			loading="lazy"
		/>
		<AText type="h4" as="h4">{{ t("favoriteEmpty.title") }}</AText>
		<AText :size="16" class="desc">
			{{ t("favoriteEmpty.txt") }}
		</AText>
	</div>
</template>

<style lang="scss" scoped>
.favorite-empty {
	text-align: center;
	margin-bottom: 28px;
	h4 {
		margin: 28px 0;
	}
	.desc {
		color: var(--gray-200);
	}
}

.image {
	max-width: 280px;
	height: auto;
}
</style>
